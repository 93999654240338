import { useState, useEffect } from 'react';
import AOS from 'aos';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from "./assets/img/logo.png";
import './assets/scss/style.scss'
import 'aos/dist/aos.css';
import MobileNav from './MobileNav';

const TemplateGuest = ({content }) => {
    const [isNavActive, setNavActive] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init({
            duration: 600,
            easing: 'ease-in-out',
            once: true,
            mirror: false
        });
    }, []);

    return (
        <>

        <header id="header"  className="header d-flex align-items-center fixed-top">
            <div className="container-fluid container-xl position-relative d-flex align-items-center">
                <Link to="/" className="logo d-flex align-items-center me-auto">
                    <img src={logo} alt={t('company name')}/>
                </Link>
                <MobileNav/>
                <Link to="/dashboard" className="btn-getstarted">
                    Aplicación
                </Link>
            </div>
        </header>

        {content}

        <footer id="footer" className="footer position-relative light-background">

            <div className="container footer-top">
                <div className="row gy-4">
                    <div className="col-lg-4 col-md-6 footer-about">
                        <Link to="/" className="logo d-flex align-items-center">

                            <span className="sitename">{t('company name')}</span>
                        </Link>
                        <div className="footer-contact pt-3">
                            <p className="mt-3"><strong>Teléfono:</strong> <span>{t('phone company')}</span></p>
                            <p><strong>Email:</strong> <span>{t('company email')}</span></p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-3 footer-links">
                        <h4>Links</h4>
                        <ul>
                            <li>
                                <Link to="/#hero">
                                    Inicio
                                </Link>
                            </li>
                            <li>
                                <Link to="/#about">
                                    Acerca de nosotros
                                </Link>
                            </li>
                            <li>
                                <Link to="/#features">
                                    Como funciona
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-4 col-md-3 footer-links">
                        <ul>
                            <li>
                                <Link to="/#services">
                                    Servicios
                                </Link>
                            </li>
                            <li className="text-capitalize-first">
                                <Link to="/#pricing">
                                    Precios
                                </Link>
                            </li>
                            <li>
                                <Link to="/#contact">
                                    Contacto
                                </Link>
                            </li>
                            <li>
                                <Link to="/dashboard">
                                    Aplicación
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container copyright text-center">
                <p> {new Date().getFullYear()} © <span>Copyright</span> <strong
                    className="px-1 sitename">{t('company name')}</strong><span>All Rights Reserved</span></p>
            </div>
        </footer>
            {/*<div id="preloader"></div>*/}

        </>
    );
};

export default TemplateGuest;