import {Button} from "react-bootstrap";
import UserFormModal from "./modal_forms/UserFormModal.jsx";
import React, {useEffect, useRef, useState} from "react";
import $ from "jquery";
import languageES from "datatables.net-plugins/i18n/es-MX.js";
import api from "../../../api/api.js";
import CompanyFormModal from "./modal_forms/CompanyFormModal.jsx";

const UsersInternal = () => {

    const tableRef = useRef(null);
    const dataTable = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const handleOpenModal = (userId = null) => {
        setSelectedUserId(userId);
        setShowModal(true);
    };

    const handleCloseModal = () =>  {
        setShowModal(false);
        if (dataTable.current) {
            dataTable.current.ajax.reload();
        }
    }

    const handleSave = () => {
        handleCloseModal();
    };

    useEffect(() => {
        dataTable.current = $(tableRef.current).DataTable({
            processing: true,
            serverSide: true,
            language: languageES,
            ajax: async (dataTablesParams, callback) => {
                try {
                    const response = await api.get('/users', {
                        params: {
                            page: dataTablesParams.start / dataTablesParams.length + 1,
                            itemsPerPage: dataTablesParams.length,
                            search: dataTablesParams.search.value,
                        },
                    });

                    callback({
                        data: response.data['hydra:member'],
                        recordsTotal: response.data['hydra:totalItems'],
                        recordsFiltered: response.data['hydra:totalItems'],
                    });
                } catch (error) {
                    console.error('Error al cargar datos:', error);
                    callback({ data: [] });
                }
            },
            columns: [
                { title: 'Nombre', render: (data, type, row) => {
                        return row.userProfile.name + " " +row.userProfile.lastname;
                    }
                },
                { title: 'Tipo', render: (data, type, row) => {
                        return row.userProfile.userType;
                    }
                },
                { title: 'email', data: 'username' },
                {
                    title: 'Acciones',
                    render: function (data, type, row) {
                        const companyId = row.id;
                        return `<button class="btn btn-primary btn-edit-user-internal" data-id="${companyId}">Editar</button>
                                <button class='btn btn-danger btn-delete-user-internal' data-id="${companyId}">Borrar</button>
                            `;
                    }
                },
                { title: 'Última actualización', data:  'createdAtAgo' },
            ],
        });

        return () => {
            if (dataTable.current) {
                dataTable.current.destroy();
            }
        };
    }, []);

    $(tableRef.current).on('click', '.btn-edit-user-internal', function () {
        const userId = $(this).data('id');
        handleOpenModal(userId);
    })

    $(tableRef.current).on('click', '.btn-delete-user-internal', function () {
        const userId = $(this).data('id');
        // handleDelete(userId);
    })


    return(
        <>
            <div className="col">
                <div className="card">
                    <div className="card-body p-1">
                        <div className="card-title p-1">
                            <h5 className="card-title p-1">Lista de usuarios</h5>
                        </div>
                        <div className={"card-body"}>
                            <table ref={tableRef} className="display" style={{width: '100%'}}></table>
                        </div>
                        <div>
                            <Button onClick={() => handleOpenModal()}>Crear Usuario</Button>

                            <UserFormModal
                                show={showModal}
                                handleClose={handleCloseModal}
                                companyId={selectedUserId}
                                onSave={handleSave}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default UsersInternal;