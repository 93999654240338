import React, { useState } from 'react';
import { Form, Button, ProgressBar, Alert, Row, Col } from 'react-bootstrap';
import api from "@/api/api";

const PasswordForm = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordMatch, setPasswordMatch] = useState(true);

    const calculatePasswordStrength = (password) => {
        let strength = 0;
        if (password.length >= 6) strength += 20;
        if (/[A-Z]/.test(password)) strength += 20;
        if (/[a-z]/.test(password)) strength += 20;
        if (/\d/.test(password)) strength += 20;
        if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)) strength += 20;
        return strength;
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setPasswordStrength(calculatePasswordStrength(newPassword));
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setPasswordMatch(e.target.value === password);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const user = sessionStorage.getItem("user");
        api.patch(`/users/${user}.json`, {
                password: password,
            },
            {
                headers: {
                    'Content-Type': 'application/merge-patch+json',
                },
            }).then((response) => {
                setPassword('');
                setConfirmPassword('');
                setPasswordStrength(0);
                setPasswordMatch(true);

        }).catch((error) => {
            console.error("ERROR:", error);
        })
        alert('Contraseña actualizada correctamente');
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Col sm={6}>
                    <Form.Group controlId="password">
                        <Form.Label>Contraseña nueva</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Ingresa tu nueva contraseña"
                            value={password}
                            onChange={handlePasswordChange}
                        />
                        <ProgressBar
                            now={passwordStrength}
                            label={`${passwordStrength}%`}
                            className="mt-2"
                        />
                        <Form.Text className="text-muted">
                            La contraseña debe contener al menos 6 caracteres, una letra mayúscula, una letra minúscula, un número y un carácter especial.
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group controlId="confirmPassword">
                        <Form.Label>Confirmar contraseña</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Confirma tu nueva contraseña"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                        />
                    </Form.Group>
                </Col>
            </Row>

            {!passwordMatch && <Alert variant="danger">Las contraseñas no coinciden</Alert>}

            <Button variant="primary" type="submit" disabled={!passwordMatch || passwordStrength < 100}>
                Actualizar contraseña
            </Button>
        </Form>
    );
};

export default PasswordForm;
