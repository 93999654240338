import { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { capitalizeFirstLetter } from "@/utils/stringUtils.js";
import "react-datepicker/dist/react-datepicker.css";

import api from "@/api/api";
import { useTranslation } from "react-i18next";

const UserProfileForm = () => {
    const { t } = useTranslation();

    const minAgeDate = new Date();
    minAgeDate.setFullYear(minAgeDate.getFullYear() - 18);

    const [formData, setFormData] = useState({
        name: "",
        lastname: "",
        birthday: null,
        telephone_contact: "",
    });

    useEffect(() => {
        const profile = sessionStorage.getItem("profile");
        api.get(`/user_profiles/${profile}.json`)
            .then((response) => {
                setFormData({
                    name: response.data.name || "",
                    lastname: response.data.lastname || "",
                    birthday: response.data.birthday ? new Date(response.data.birthday) : null,
                    telephone_contact: response.data.telephone_contact || "",
                });
            })
            .catch((error) => {
                console.error("ERROR:", error);
            });
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleDateChange = (date) => {
        setFormData({ ...formData, birthday: date });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const profile = sessionStorage.getItem("profile");
        api.patch(`/user_profiles/${profile}.json`, formData,
            {
                headers: {
                    'Content-Type': 'application/merge-patch+json',
                },
            }).then((response) => {
            console.log(response.data);

        }).catch((error) => {
            console.error("ERROR:", error);
        })
    }
    return (
        <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Col md={6}>
                    <Form.Group>
                        <Form.Label className="fw-bold">{capitalizeFirstLetter(t("name"))}</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={formData.name || ""}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>

                <Col md={6}>
                    <Form.Group>
                        <Form.Label className="fw-bold">{capitalizeFirstLetter(t("lastname"))}</Form.Label>
                        <Form.Control
                            type="text"
                            name="lastname"
                            value={formData.lastname || ""}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col md={6}>
                    <Form.Group className="w-100">
                        <Form.Label className="fw-bold w-100">{capitalizeFirstLetter(t("date of birth"))}</Form.Label>
                        <DatePicker
                            selected={formData.birthday}
                            onChange={handleDateChange}
                            style={{ width: "100%" }}
                            className="form-control w-100"  // 🔹 Hace que el DatePicker tenga el mismo tamaño que los inputs
                            dateFormat="yyyy-MM-dd"
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={100}
                            placeholderText={t("select_date")}
                            maxDate={minAgeDate}
                        />
                    </Form.Group>
                </Col>

                <Col md={6}>
                    <Form.Group>
                        <Form.Label className="fw-bold">{capitalizeFirstLetter(t("telephone contact"))}</Form.Label>
                        <Form.Control
                            type="text"
                            name="telephone_contact"
                            value={formData.telephone_contact || ""}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Button type="submit" variant="primary">{capitalizeFirstLetter(t("save"))}</Button>
        </Form>
    );
};

export { UserProfileForm };
