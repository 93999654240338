import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
//import { useSidebar } from './../../context/SidebarContext.jsx';
import menuItems from './menuItems.js';

const TemplateAside = () => {
    const { t } = useTranslation();
    //const { isSidebarOpen } = useSidebar();
    const userRoles = JSON.parse(sessionStorage.getItem('roles')) || [];


    const hasAccess = (allowedRoles) => allowedRoles.some(role => userRoles.includes(role));

    // Filtrar menú y submenús basados en los roles del usuario
    const filteredMenuItems = menuItems
    .map(item => ({
        ...item,
        subMenu: item.subMenu
        ? item.subMenu.filter(subItem => hasAccess(subItem.allowedRoles))
        : null
    }))
    .filter(item => hasAccess(item.allowedRoles) || (item.subMenu && item.subMenu.length > 0));

    return (
        <>
            <aside id="sidebar"  className="pt-4 sidebar">
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link to={"/dashboard"} className="nav-link" >
                            <i className="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    {filteredMenuItems.map((item, index) => (
                        <li key={item.key} className="nav-item">
                        {item.subMenu && item.subMenu.length > 0 ? (
                            <>
                            <a
                                href="#"
                                className="nav-link collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target={`#submenu-${index}`}
                                aria-expanded="false"
                            >
                                <i className={`bi ${item.icon}`}></i>
                                <span className={"text-capitalize-first"}>{t(item.name)}</span>
                                <i className="bi bi-chevron-down ms-auto"></i>
                               
                            </a>
                            {/* Submenú colapsable */}
                            <div id={`submenu-${index}`} className="collapse">
                                <ul className="nav flex-column nav-content ps-3" data-bs-parent="#sidebar-nav">
                                    {item.subMenu.map(subItem => (
                                        <li key={subItem.path}>
                                            <Link to={subItem.path} >
                                                <i className={`bi ${subItem.icon}`}></i>
                                                <span className={"text-capitalize-first"}>{t(subItem.name)}</span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            </>
                        ) : (
                            <Link to={item.path} className="nav-link collapsed">
                                <i className={`bi ${item.icon}`}></i><span>{item.name}</span>
                            </Link>
                        )}
                        </li>
                    ))}
                </ul>

            </aside>
        </>
    );
}

export default TemplateAside;