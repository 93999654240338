const menuItems = [
    {
        key: 1,
        name: 'Usuarios',
        icon: 'bi-person',
        path: '',
        allowedRoles: ['ROLE_ADMIN'],
        subMenu: [
            { key: 1.1, name: 'Usuarios', icon: 'bi-person', path: '/users-internal', allowedRoles: ['ROLE_ADMIN'] },
        ]
    },
    {
        key: 2,
        name: 'Compañias',
        icon: 'bi-building',
        path: '',
        allowedRoles: ['ROLE_ADMIN'],
        subMenu: [
            { key:2.1, name: 'Compañias', icon: '', path: '/companies', allowedRoles: ['ROLE_ADMIN'] },
        ]
    },
    {
        key: 3,
        name: 'Folios',
        icon: 'bi-ticket-fill',
        path: '',
        allowedRoles: ['ROLE_ADMIN'],
        subMenu: [
            { key: 3.1, name: 'Folios comprados', icon: 'bi-ticket-detailed', path: '/folios', allowedRoles: ['ROLE_ADMIN'] },
            { key: 3.2, name: 'Folios facturas', icon: 'bi-ticket-detailed', path: '/folios_facturas', allowedRoles: ['ROLE_ADMIN'] },
        ],
    },
    {
        key: 4,
        name: 'Perfil',
        icon: 'bi-person-square',
        path: '',
        allowedRoles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_GUEST'],
        subMenu: [
            { key: 4.1, name: 'your profile', icon: 'bi-person-bounding-box', path: '/my_profile', allowedRoles: ['ROLE_USER'] },
        ]
    },
  ];
  
  export default menuItems;