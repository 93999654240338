const UsersCompanies = () => {
    return(
        <>
        <div className="col">
            <div className="card">
                <div className="card-body p-1">
                    <div className="card-title p-1">
                        <h5 class="card-title p-1">Lista de compañias</h5>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default UsersCompanies;