import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            "company name": "CFD-I Punto",
            "company email": "contact@cfd-ipunto.com",
            "phone company": "55 563432-0728",
            "activity": "activity",
            "profile": "profile",
            "login": "login",
            "sign into": "Sign In to your account",
            "username": "username",
            "password": "password",
            "save": "save",
            "submit": "submit",
            "forgot password": "forgot password",
            "home": "home",
            "name": "name",
            "lastname": "lastname",
            "about": "about",
            "services": "services",
            "security": "security",
            "portafolio": "portafolio",
            "contact": "contact",
            "useful links": "useful links",
            "our services": "our services",
            "contact us": "contact us",
            "email": "email",
            "terms of service" : "terms of service",
            "privacy policy" : "privacy policy",
            "internal links" : "internal links",
            "application" : "application",
            "my profile": "my profile",
            "your profile": "my profile",
            "account settings": "account settings",
            "sign out": "sign out",
            "clients":"clients",
            "list clients":"list clients",
            "billing":"billing",
            "reports":"reports",

        }
    },
    es: {
        translation: {
            "company name": "CFD-I Punto",
            "phone company": "55 563432-0728",
            "company email": "contact@cfd-ipunto.com",
            "activity": "actividad",
            "profile": "perfil",
            "login": "iniciar sesión",
            "sign into": "Ingresa a tu cuenta",
            "username": "nombre de usuario",
            "password": "contraseña",
            "submit": "enviar",
            "save": "guardar",
            "forgot password": "olvidaste la contraseña",
            "home": "inicio",
            "name": "nombre",
            "lastname": "apellido",
            "about": "acerca",
            "services": "servicios",
            "security": "seguridad",
            "portafolio": "portafolio",
            "contact": "contacto",
            "useful links": "links",
            "our services": "nuestros servicios",
            "contact us": "contactanos",
            "email": "email",
            "terms of service" : "términos del servicio",
            "privacy policy" : "política de privacidad",
            "internal links" : "links internos",
            "application" : "aplicación",
            "my profile": "mi perfil",
            "your profile": "tu perfil",
            "account settings": "ajustes de la cuenta",
            "sign out": "salir",
            "clients":"clientes",
            "date of birth":"dia de nacimiento",
            "telephone contact":"teléfono de contacto",
            "list clients":"lista de clientes",
            "billing":"facturas",
            "reports":"reportes",
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'es',
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;