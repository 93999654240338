import { useState } from "react";
import {useTranslation} from "react-i18next";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {UserProfileForm} from "./forms/UserProfileForm.jsx";
import { capitalizeFirstLetter} from "@/utils/stringUtils.js";
import PasswordForm from "./forms/PasswordForm.jsx";


const UserProfile = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("home");

    return (
        <>
            <div className="container py-5">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <Tabs
                                    activeKey={activeTab}
                                    onSelect={(k) => setActiveTab(k)}
                                    id="uncontrolled-tab-example"
                                    className="mb-3 text-capitalize-first"
                                    justify
                                >
                                    <Tab eventKey="home" title={capitalizeFirstLetter(t('home'))}>
                                        <UserProfileForm />
                                    </Tab>
                                    <Tab eventKey="security" title={capitalizeFirstLetter(t('security'))}>
                                        <PasswordForm />
                                    </Tab>
                                    <Tab eventKey="activity" title={capitalizeFirstLetter(t('activity'))}>
                                        List of activities
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );


}

export default UserProfile;